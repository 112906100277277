import React, { Component } from 'react';
import { connect } from 'react-redux';

import Games from './GamesComponent';

import Spin from './img/pregames/spin-the-wheel.png';
import Scratch from './img/pregames/scratch-cards.png';
import Knock from './img/pregames/knock-knock.png';

import Memory from './img/games/memory.png';
import Difference from './img/games/spot-the-difference.png';
import Quiz from './img/games/quiz.261.557.png';
import Drag from './img/games/drag-and-drop.png';
import Puzzle from './img/games/puzzle.png';

import {userEvent} from '../../../user/store/selectors';

import {
	playGetExternalGames,
	playGetGames,
	playStartGame,
} from "../../store/selectors";

import {
	playLoadExternalGames,
	playLoadGames,
	playLoadStartGame,
} from "../../store/actions";
import {uiRedirect} from '../../../../store/actions';
import {userLoadEvent} from '../../../user/store/actions';
import {appGetInterfaceLang} from '../../../../store/selectors';


const stateToProps = state => ({
	playGetGames: playGetGames(state),
	playStartGame: playStartGame(state),
	playGetExternalGames: playGetExternalGames(state),
	userEvent: userEvent(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	playLoadGames: (payload) => dispatch(playLoadGames(payload)),
	playLoadExternalGames: (payload) => dispatch(playLoadExternalGames(payload)),
	playLoadStartGame: (payload) => dispatch(playLoadStartGame(payload)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
});

@connect(stateToProps, actionsToProps)
class GamesContainer extends Component {

	state = {
		userInfoGames: this.props.userInfo ? this.props.userInfo.games : '',
		pregames: [
			{
				title: "Spin the Wheel",
				image: Spin,
				locked: false,
			},
			{
				title: "Scratch Cards",
				image: Scratch,
				locked: false,
			},
			{
				title: "Knock-Knock",
				image: Knock,
				locked: false,
			},
		],
		games: [
			{
				title: "Memory game",
				image: Memory,
				locked: false,
				completed: true,
			},
			{
				title: "Spot the Difference",
				image: Difference,
				locked: false,
				completed: false,
			},
			{
				title: "Quiz",
				image: Quiz,
				locked: false,
				completed: false,
			},
			{
				title: "Drag & Drop",
				image: Drag,
				locked: true,
				completed: false,
			},
			{
				title: "Puzzle",
				image: Puzzle,
				locked: true,
				completed: false,
			},
		]
	}

	handlePlayGame = (e, id, gameUrl) => {
		e.preventDefault();
		if(this.state.virtualGames) {
			this.setState({
				redirectTo: gameUrl
			}, () => this.props.userLoadEvent({ type: 'ACTIVITY_START', metadata: `{"activity_session_id": ${Math.floor(Math.random() * 1000)}, "activity_type": 3, "activity_subtype": ${id}}`}))
		} else {
			if(this.props.userInfo.games[id] === 'playable' || this.props.userInfo.games[id] === 'playing') {
				this.props.uiRedirect({
					pathname: `/play/${id}`,
					state: {
						runGame: true,
					}
				})
			}
		}
	}

	componentDidMount() {
		const path = this.props.location.pathname;

		this.props.playLoadExternalGames();
		if(path === '/play') {
			if(this.props.userInfo.games) {
				this.props.playLoadGames();
			}
		} else if(path === '/play-virtual') {
			this.setState({
				virtualGames: true
			})
			if(this.props.userInfo.games) {
				this.props.playLoadExternalGames();
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const path = this.props.location.pathname;

		if(prevProps.playGetGames !== this.props.playGetGames) {
			this.setState({
				playGetGames: this.props.playGetGames
			})
		}

		if(prevProps.playGetExternalGames !== this.props.playGetExternalGames) {
			this.setState({
				playGetGames: this.props.playGetExternalGames
			})
		}

		if(prevProps.userInfo !== this.props.userInfo) {

			if(path === '/play') {
				this.props.playLoadGames();
			} else if(path === '/play-virtual') {
				this.props.playLoadExternalGames();
			}
			if(prevProps.userInfo.games !== this.props.userInfo.games) {
				this.setState({
					userInfoGames: this.props.userInfo.games
				})
			}
		}

		if(prevProps.userEvent !== this.props.userEvent && this.props.userEvent === 'ACTIVITY_START') {
			window.location.href = this.state.redirectTo;
		}
	}

	render() {
		if(!this.state.playGetGames || !this.state.userInfoGames) {
			return null;
		}
		return (
			<Games
				lang={this.props.lang}
				virtualGames={this.state.virtualGames}
				games={this.state.playGetGames}
				userInfoGames={this.state.userInfoGames}
				userInfo={this.props.userInfo}
				handlePlayGame={this.handlePlayGame}
			/>
		);
	}
}

export default GamesContainer;
