import { createAction } from 'redux-actions';

import * as USER from './types';

const loadMsg = createAction(USER.LOAD_MSG);
const setMsg = createAction(USER.SET_MSG);
const userLoadEvent = createAction(USER.USER_LOAD_EVENT, data => data);
const userLoadInfo = createAction(USER.USER_LOAD_INFO);
const userLoadUpdateInfo = createAction(USER.USER_LOAD_UPDATE_INFO);
const userLoadPoints = createAction(USER.USER_LOAD_POINTS);
const userLoadCredits = createAction(USER.USER_LOAD_CREDITS);
const userLoadLeaderBoard = createAction(USER.USER_LOAD_LEADER_BOARD);
const userLoadUnsubscribe = createAction(USER.USER_LOAD_UNSUBSCRIBE);

export {
	loadMsg,
	setMsg,
	userLoadEvent,
	userLoadInfo,
	userLoadUpdateInfo,
	userLoadPoints,
	userLoadCredits,
	userLoadLeaderBoard,
	userLoadUnsubscribe,
}
