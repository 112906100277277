import React, {Component} from 'react';
import {connect} from 'react-redux';

import Unsubscribe from './UnsubscribeComponent';
import { apiPost } from 'AppUtils/api';
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import {
	appGetConfig
} from 'AppStore/selectors';
import { userInfo } from "../../user/store/selectors";

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class UnsubscribeContainer extends Component {

	constructor(props) {
		super(props);

		let unsubscribeMethod = props.appGetConfig.config.users.unsubscribeMethod;
		let authentication = getValue(props, 'userInfo.eligibility.authentication');

		this.state = {
			unsubscribeMethod,
			authenticationNotManual: authentication && authentication != 'manual',
		}
	}

	render() {
			return (
				<Unsubscribe
					unsubscribeMethod={this.state.unsubscribeMethod}
					authenticationNotManual={this.state.authenticationNotManual}
					msisdn={this.props.appGetConfig && this.props.appGetConfig.config && this.props.appGetConfig.config.users ? this.props.appGetConfig.config.users.msisdn : false}
					pin={this.props.appGetConfig && this.props.appGetConfig.config && this.props.appGetConfig.config.users ? this.props.appGetConfig.config.users.pin : false}
				/>
			);
	}
}

export default UnsubscribeContainer;
