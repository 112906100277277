import React from 'react';

import Msisdn from '../subcomponents/msisdn/MsisdnContainer';
import Password from '../subcomponents/password/PasswordContainer';

const UnsubscribePromptComponent = (props) => {
		return (
			<div className="unsubscribe-prompt-component">
				{props.confirmPass ?
					<Password
						{...props}
					/>
					:
					<Msisdn
						{...props}
					/>
				}
			</div>
		);
};

export default UnsubscribePromptComponent;
