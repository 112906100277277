const PLAY_LOAD_MSG = 'PLAY_LOAD_MSG';
const PLAY_SET_MSG = 'PLAY_SET_MSG';

const PLAY_LOAD_GAMES = 'PLAY_LOAD_GAMES';
const PLAY_SET_GAMES = 'PLAY_SET_GAMES';

const PLAY_LOAD_EXTERNAL_GAMES = 'PLAY_LOAD_EXTERNAL_GAMES';
const PLAY_SET_EXTERNAL_GAMES = 'PLAY_SET_EXTERNAL_GAMES';

const PLAY_LOAD_START_GAME = 'PLAY_LOAD_START_GAME';
const PLAY_SET_START_GAME = 'PLAY_SET_START_GAME';

const PLAY_LOAD_FINISH_GAME = 'PLAY_LOAD_FINISH_GAME';
const PLAY_SET_FINISH_GAME = 'PLAY_SET_FINISH_GAME';

export {
	PLAY_LOAD_MSG,
	PLAY_SET_MSG,
	PLAY_LOAD_GAMES,
	PLAY_SET_GAMES,
	PLAY_LOAD_EXTERNAL_GAMES,
    PLAY_SET_EXTERNAL_GAMES,
	PLAY_LOAD_START_GAME,
	PLAY_SET_START_GAME,
	PLAY_LOAD_FINISH_GAME,
	PLAY_SET_FINISH_GAME,
};
