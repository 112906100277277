import React from 'react';
import clsx from 'clsx';

import './Button.scss';

const ButtonComponent = (props) => {
	return (
		<div
			className={clsx(
				{
					btn: true,
					'full-width': props.fullWidth,
					'box-shadow': props.boxShadow,
					'outline' : props.outline
				}
				)}
			style={{
				lineHeight: props.height+'px',
				paddingLeft: props.paddingSides,
				paddingRight: props.paddingSides
			}}
			onClick={props.onClick ? () => { props.onClick()} : null}
		>
			{props.children}
		</div>
	);
};

export default ButtonComponent;
