import React, {Component} from 'react';
import {connect} from 'react-redux';

import About from './AboutComponent';

import { userLoadUpdateInfo } from '../user/store/actions';
import {authIsAuthenticated} from '../auth/store/selectors';
import {appGetInterfaceLang} from '../../store/selectors';

const stateToProps = state => ({
	authIsAuthenticated: authIsAuthenticated(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	userLoadUpdateInfo: (payload) => dispatch(userLoadUpdateInfo(payload)),
});

@connect(stateToProps, actionsToProps)
class AboutContainer extends Component {

	state = {
		slideIndex: this.props.lang === 'ar' ? 3 : 0,
		updateCount: 0
	};

	sliderRef = React.createRef();

	handleNext = (e) => {
		if(this.state.slideIndex < 3) {
			e.preventDefault();
			this.sliderRef.current.slickGoTo(this.state.slideIndex + 1)
		} else {
			if(this.props.authIsAuthenticated) {
				localStorage.setItem('onboardingVsa', true);
				this.props.userLoadUpdateInfo({ settings: `{"onboardingSeen": true}` });
			}
		}
	}

	handleNextLtr = (e) => {
		console.log(this.sliderRef.current.props.children.length)
		if(this.state.slideIndex > 0) {
			e.preventDefault();
			this.sliderRef.current.slickGoTo(this.state.slideIndex - 1)
		} else {
			if(this.props.authIsAuthenticated) {
				localStorage.setItem('onboardingVsa', true);
				this.props.userLoadUpdateInfo({ settings: `{"onboardingSeen": true}` });
			}
		}
	}

	// componentDidMount() {
	// 	if(this.props.lang === 'ar') {
	// 		this.setState({
	// 			slideIndex: 3,
	// 		})
	// 	}
	// }

	// componentDidUpdate(prevProps, prevState, snapshot) {
	// 	if(prevProps.lang !== this.props.lang) {
	// 		if(this.props.lang === 'ar') {
	// 			this.setState({
	// 				slideIndex: 4,
	// 				updateCount: 0
	// 			})
	// 		}
	// 	}
	// }

	render() {
		const settings = {
			dots: true,
			arrows: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			rtl: this.props.lang === 'ar' ? true : false,
			afterChange: () =>

			{
				if(!this.props.lang === 'ar') {
					this.setState(state => ({ updateCount: state.updateCount + 1 }))
				} else {
					this.setState(state => ({ updateCount: state.updateCount + 1 }))
				}
			},
			beforeChange: (current, next) => {
				if(!this.props.lang === 'ar') {
					this.setState({ slideIndex: next })
				} else {
					this.setState({ slideIndex: next })
				}
			}
		};

		return (
			<About
				lang={this.props.lang}
				settings={settings}
				slideIndex={this.state.slideIndex}
				sliderRef={this.sliderRef}
				handleNext={this.handleNext}
				handleNextLtr={this.handleNextLtr}
			/>
		);
	}
}

export default AboutContainer;
