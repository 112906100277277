import React, {Component} from 'react';
import {connect} from 'react-redux';
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import Menu from './MenuComponent';
import { appLoadTranslations, uiRedirect, uiToggleModal } from '../../../store/actions';
import {appGetConfig} from 'AppStore/selectors';
import { userInfo, userUnsubscribe } from "../../user/store/selectors";
import { userLoadUnsubscribe } from "../../user/store/actions";

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	appLoadTranslations: (data) => dispatch(appLoadTranslations(data)),
	userLoadUnsubscribe: () => dispatch(userLoadUnsubscribe()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class MenuContainer extends Component {

	constructor(props) {
		super(props);
		let authentication = getValue(props, 'userInfo.eligibility.authentication');

		this.state = {
			authenticationNotManual: authentication && authentication != 'manual',
			unsubscribeMethod: this.props.appGetConfig.config.users.subscribeMethod,
			modalIsOpen: false,
			currentLanguage: localStorage.getItem('languageVsa') || process.env.REACT_APP_DEFAULT_LANGUAGE,
			menuItems: [
				{
					text: getText('menu[button_about]'),
					link: '/about',
					restricted: false
				},
				{
					text: getText('menu[button_play]'),
					link: '/play',
					restricted: true
				},
				{
					text: getText('menu[button_play_virtual]'),
					link: '/play-virtual',
					restricted: true
				},
				{
					text: getText('menu[button_profile]'),
					link: '/profile',
					restricted: true
				},
				{
					text: getText('menu[button_top]'),
					link: '/leaderboard',
					restricted: true
				},
				// {
				// 	text: getText('menu[button_library]'),
				// 	link: '/library',
				// 	restricted: false
				// },
				// {
				// 	text: getText('menu[button_tos]'),
				// 	link: '/tos',
				// 	restricted: false
				// },
				{
					text: getText('menu[button_faq]'),
					link: '/faq',
					restricted: false
				},
				{
					text: getText('menu[button_language]'),
					link: '/',
					restricted: false,
					callback: true
				},
				{
					text: getText('menu[button_unsubscribe]'),
					link: '/unsubscribe',
					restricted: true,
				},
				{
					text: getText('menu[button_logout]'),
					link: '/logout',
					restricted: true,
					loggedInOnly: true
				},
			]
		}
	}

	openModal = () => {
		this.setState({
			modalIsOpen: true
		})
	}

	openGeneralModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	closeModal = () => {
		this.setState({
			modalIsOpen: false
		})
	}

	handleChangeLanguage = (e) => {
		const languageValue = e.target.value;
		this.setState({
			currentLanguage: e.target.value
		}, () => {
			this.props.appLoadTranslations({lang: languageValue})
			localStorage.setItem('languageVsa', languageValue);
		});
	}

	componentDidUpdate(prevProps) {
		let authentication = getValue(this.props, 'userInfo.eligibility.authentication');
		let prevAuthentication = getValue(prevProps, 'userInfo.eligibility.authentication');

		if (authentication != prevAuthentication) {
			this.setState({
				authenticationNotManual: authentication && authentication != 'manual',
			});
		}
	}

	render() {
		return (
			<Menu
				{...this.state}
				appGetConfig={this.props.appGetConfig}
				menuIsOpen={this.props.menuIsOpen}
				toggleMenu={this.props.toggleMenu}
				openModal={this.openModal}
				openGeneralModal={this.openGeneralModal}
				closeModal={this.closeModal}
				authIsAuthenticated={this.props.authIsAuthenticated}
				authStatus={this.props.authStatus}
				authLogout={this.props.authLogout}
				uiRedirect={this.props.uiRedirect}
				handleChangeLanguage={this.handleChangeLanguage}
				userLoadUnsubscribe={this.props.userLoadUnsubscribe}
			/>
		);
	}
}

export default MenuContainer;
