import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import cardImages from "./cards";
import Card from "./Card/Card";
import deepcopy from "deepcopy";
import Timer from 'react-compound-timer'

import './MemoryGame.scss';
import '../Timer.scss';

import iconTimer from '../../main/img/icon-timer.png';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
	</Timer>
);

function MemoryGame(props) {
	function shuffleArray(array) {
		return array.sort(() => .5 - Math.random());
	}

	function generateCards(count) {
		if (count % 2 !== 0)
			throw "Count must be even: 2, 4, 6, etc. but it is " + count;

		const cards = shuffleArray(props.playGetGame.data.boxes)
			.slice(0, count / 2)
			.map(box => ({
				id: uuidv4(),
				imageURL: box.image,
				isFlipped: false,
				canFlip: true
			}))
			.flatMap(e => [e, {...deepcopy(e), id: uuidv4()}]);

		return shuffleArray(cards);
	}


	const totalCards = props.playGetGame.data.boxes.length * 2;

	const [cards, setCards] = useState(generateCards(totalCards));
	const [canFlip, setCanFlip] = useState(false);
	const [firstCard, setFirstCard] = useState(null);
	const [secondCard, setSecondCard] = useState(null);
	const [correctCount, setCorrectCount] = useState(0);
	const [timeFinished, setTimeFinished] = useState(null);

	function setCardIsFlipped(cardID, isFlipped) {
		setCards(prev => prev.map(c => {
			if (c.id !== cardID)
				return c;
			return {...c, isFlipped};
		}));
	}
	function setCardCanFlip(cardID, canFlip) {
		setCards(prev => prev.map(c => {
			if (c.id !== cardID)
				return c;
			return {...c, canFlip};
		}));
	}

	// showcase
	useEffect(() => {
		props.timer.setTime(props.playGetGame.data.maxSeconds * 1000 + 500);
		setTimeout(() => {
			let index = 0;
			for (const card of cards) {
				setTimeout(() => setCardIsFlipped(card.id, true), index++ * 100);
			}
			setTimeout(() => setCanFlip(true), cards.length * 100), props.timer.start();
		}, 3000);
	}, []);


	function resetFirstAndSecondCards() {
		setFirstCard(null);
		setSecondCard(null);
	}

	function onSuccessGuess() {
		setCardCanFlip(firstCard.id, false);
		setCardCanFlip(secondCard.id, false);
		setCardIsFlipped(firstCard.id, false);
		setCardIsFlipped(secondCard.id, false);
		resetFirstAndSecondCards();
		setCorrectCount(correctCount+1)
	}
	function onFailureGuess() {
		const firstCardID = firstCard.id;
		const secondCardID = secondCard.id;

		setTimeout(() => {
			setCardIsFlipped(firstCardID, true);
		}, 1000);
		setTimeout(() => {
			setCardIsFlipped(secondCardID, true);
		}, 1200);

		resetFirstAndSecondCards();
	}

	function finishGame() {
		// setTimeFinished(parseInt(props.timer.getTime()/1000))
		props.uiRedirect({
			pathname: '/result',
			state: {
				gamePlayId: parseInt(props.playStartGame.id),
				time: parseInt(props.timer.getTime()/1000) === 0 ? -1 : parseInt(props.timer.getTime()/1000),
			}
		})

		if(timeFinished !== null) {

		}
	}

	useEffect(() => {
		if (!firstCard || !secondCard)
			return;
		(firstCard.imageURL === secondCard.imageURL) ? onSuccessGuess() : onFailureGuess();
	}, [firstCard, secondCard]);

	useEffect(() => {
		if(correctCount === totalCards/2) {
			props.timer.pause();
			setTimeout(() => {
				finishGame();
			}, 1000)
			console.log('finished')
		}
	});

	//Timer finished
	useEffect(() => {
		props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					finishGame()
				},
			},
		]);
	});


	function onCardClick(card) {
		if (!canFlip)
			return;
		if (!card.canFlip)
			return;

		if ((firstCard && (card.id === firstCard.id) || (secondCard && (card.id === secondCard.id))))
			return;

		setCardIsFlipped(card.id, false);

		(firstCard) ? setSecondCard(card) : setFirstCard(card);
	}

	return <div className="memory-game container-md">
		<div className="title">
			<h2>{props.playGetGame.name[props.lang]}</h2>
			<p>{props.playGetGame.subtitle[props.lang]}</p>
		</div>
		<div className="game-timer">
			<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
		</div>
		<div className="cards-container">
			{cards.map(card => <Card onClick={() => onCardClick(card)} key={card.id} {...card} defaultImage={props.playGetGame.data.defaultImage}/>)}
		</div>
	</div>;
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60500,
	startImmediately: false,
})(MemoryGame);

<TimerHOC />

export default TimerHOC;
