import React from "react";

import './Card.scss';

export default function Card({imageURL, isFlipped, onClick, defaultImage}) {
	return <div className="card-container" onClick={onClick}>
		<div
			className={"card" + (isFlipped ? " flipped" : "")}
		>
			<div className="side front" style={{backgroundImage: `url(${imageURL})`}}></div>
			{/*<img src={imageURL} className="side front" />*/}
			<div className="side back" style={{backgroundImage: `url(${defaultImage})`}}/>
		</div>
	</div>;
}
